<template>
  <main class="table-font ">
    <TailwindDataTable
      :loading="isLoading"
      :tableData="accounts"
      :renderedItems="renderedItems"
      title="VFD Crediting Portal"
      @search="search = $event"
      :params="payload"
      @update="updateData"
      :cycle="cycle"
    >
      <template v-slot:button>
        <button
          @click="
            $router.push({
              name: 'VFDPortalNewVFD',
            })
          "
          class="inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-gray-900 text-white hover:bg-primary/90 h-10 py-2 px-4 rounded-md"
        >
          Create VFD Account
        </button>
      </template>
      <!-- <template v-slot:extra="{ slotData }">
        <button
          @click="toggleModal(slotData)"
          class="inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-gray-900 text-white hover:bg-primary/90 h-10 py-2 px-4 rounded-md"
        >
          Action
        </button>
      </template> -->
    </TailwindDataTable>

    <Modal size="max-w-xl" v-if="openModal" @close="openModal = false">
      <div>
        <div class="flex items-center justify-center mt-12">
          <svg
            width="100"
            height="100"
            viewBox="0 0 142 142"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="71" cy="71" r="71" fill="#FFF8DD" />
            <path
              d="M78.2618 33.3485L76.8315 85.9384H63.409L61.9421 33.3485H78.2618ZM70.1203 109.409C67.6998 109.409 65.6217 108.554 63.8858 106.842C62.1499 105.106 61.2942 103.028 61.3186 100.608C61.2942 98.2118 62.1499 96.1581 63.8858 94.4467C65.6217 92.7352 67.6998 91.8795 70.1203 91.8795C72.4429 91.8795 74.4844 92.7352 76.2448 94.4467C78.0051 96.1581 78.8975 98.2118 78.9219 100.608C78.8975 102.221 78.4696 103.701 77.6384 105.045C76.8315 106.366 75.768 107.429 74.4478 108.236C73.1275 109.018 71.685 109.409 70.1203 109.409Z"
              fill="#E8B637"
            />
          </svg>
        </div>
        <div class="text-center text-xl font-medium px-32 mt-5">
          Are you sure you want to push this loan to disbursal?
        </div>
        <div class="px-32 flex justify-between items-center mt-10 mb-8">
          <button
            @click="disburse"
            :disabled="buttonLoad"
            class=" bg-blue-600 py-3  w-32 px-6 rounded-md  text-white"
          >
            Yes
          </button>
          <button
            @click="openModal = false"
            class=" bg-red-600 py-3  w-32 px-6 rounded-md  text-white"
          >
            No
          </button>
        </div>
      </div>
    </Modal>

    <Modal size="max-w-xl" v-if="successModal" @close="successModal = false">
      <div>
        <div class="flex items-center justify-center mt-12">
          <svg
            width="100"
            height="100"
            viewBox="0 0 143 142"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_38_14)">
              <circle cx="71.7041" cy="71" r="71" fill="#EBFFED" />
              <path
                d="M100.104 49.7001L61.0542 88.7501L43.3042 71.0001"
                stroke="#10C64E"
                stroke-width="7.1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_38_14">
                <rect
                  width="142"
                  height="142"
                  fill="white"
                  transform="translate(0.704102)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="text-center text-xl font-medium px-32 mt-5">
          Successful
        </div>
        <p class=" text-center mt-1 mb-6">
          Your loan has been pushed to disbursal
        </p>
      </div>
    </Modal>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import Loading from './general-components/Loading.vue'
import Modal from '../components/Modal.vue'
import { debounce } from '../utils/helper'

export default {
  name: 'Loans',
  data() {
    return {
      isLoading: false,
      renderedItems: [
        { name: 'Borrower Name', key: 'borrower_name' },
        { name: 'Borrower Phone ', key: 'borrower_phone' },
        { name: 'Business Name', key: 'business_name' },
        { name: 'Tenor', key: 'tenor' },
        { name: 'VFD Account Number', key: 'repayment_fixed_account_number' },
        { name: 'Loan Amount', key: 'loan_amount_outstanding' },
      ],
      search: '',
      moment,
      accounts: [],
      openModal: false,
      currentLoan: {},
      buttonLoad: false,
      successModal: false,
      payload: {
        search: '',
        pageNumber: '1',
        pageSize: '100',
        hasNextPage: false,
        hasPrevPage: false,
        totalCount: '',
        totalPages: '',
      },
      cycle: 0,
    }
  },
  components: {
    Loading,
    Modal,
  },
  computed: {
    ...mapGetters(['GET_LOANS']),
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
  },
  watch: {
    search: debounce(function(newVal) {
      this.payload.search = newVal.toLowerCase()
      this.payload.pageNumber = '1'
      this.payload.pageSize = '100'
      this.payload.hasNextPage = false
      this.payload.hasPrevPage = false
      this.payload.totalCount = ''
      this.payload.totalPages = ''
      this.fetchVfd()
    }, 3000),
  },
  methods: {
    debounce(fn, delay) {
      var timeoutID = null
      return function() {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function() {
          fn.apply(that, args)
        }, delay)
      }
    },
    toggleModal(loan) {
      this.currentLoan = loan
      this.openModal = true
    },
    async fetchVfd() {
      this.isLoading = true
      try {
        const body = this.payload

        let res = await this.$store.dispatch('GET_ALL_VFD', body)
        this.accounts = res.data.data
        let {
          hasNextPage,
          hasPrevPage,
          totalCount,
          totalPages,
          pageNumber,
          pageSize,
        } = res.data.meta
        this.payload = {
          ...this.payload,
          hasNextPage,
          hasPrevPage,
          totalCount: totalCount.toString(),
          totalPages: totalPages.toString(),
          pageNumber: pageNumber.toString(),
          pageSize: pageSize.toString(),
        }
        this.isLoading = false
      } catch (error) {
        console.log(error, 'error')
      }

      this.isLoading = false
    },
    async disburse() {
      this.buttonLoad = true
      try {
        let res = await this.$store.dispatch('DISBURSE_VFD', {
          AccountNo: this.currentLoan.repayment_fixed_account_number,
          LoanAmount: this.currentLoan.loan_amount,
          Duration: this.currentLoan.tenor,
        })
        if (res) {
          this.successModal = true
          this.openModal = false
        }
        this.buttonLoad = false
        this.fetchVfd()
      } catch (error) {
        console.log(error, 'error')
      }

      this.buttonLoad = false
    },
    updateData(payload) {
      const data = payload.map((item) => {
        return {
          name: item.name,
          key: item.value,
        }
      })
      this.renderedItems = data
      this.cycle += 1
    },
  },
  async mounted() {
    await this.fetchVfd()
  },
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}

table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}

table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}

table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}

table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}

table tr td {
  background: #f0f6fd;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
